/* App.css */

body{
  background-color: #F9FAFB;
  user-select: none;
}
.page {
  font-family: Arial, sans-serif;
}

.menu {
  display: flex;
  justify-content: space-between;
  padding: 0px 10%;
  background-color: #fff;
}

.logo img {
  width: 50px;
  margin-top: 30px !important;
}

.buttons {
  display: flex;
  gap: 10px;
}

.profile img {
  width: 50px;
  border-radius: 50%;
}

.menu-button {
  background-color:#fff;
  box-shadow:none;
  color:black;
  height: 100px !important;
  border-radius: 0px !important;
}

.mainBtn{
  border-bottom: 3px solid orange !important;
}

.menu-button:hover {
  background-color:#f0f0f0 !important;
  color: black !important;
  border-bottom: 3px solid orange;
}

.pad{
  padding: 0 !important;
}

.container {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 200px;
  border: none;
}

.left-icon,
.right-icon {
  flex: 0 0 auto;
}

.center-content {
  flex: 1 1 auto;
  text-align: center;
}

.center-content p {
  margin: 0;
}

.left-icon img,
.right-icon img {
  max-width: 50px;
  height: auto;
}

.custom-table-container .ant-table {
  border-radius: 0 !important; /* Table'ın köşelerini sıfırla */
}

.custom-table-container .ant-table-thead th:last-child {
  border-top-right-radius: 0 !important; /* Sağ üst köşeyi sıfırla */
}

.custom-table-container .ant-table-thead th:first-child {
  border-top-left-radius: 0 !important; /* Sol üst köşeyi sıfırla */
}

.custom-reference-dot {
  .recharts-label {
    transform: translateY(10px); /* Label'ı aşağıya kaydır */
  }
}

.custom-reference-dotMax {
  .recharts-label {
    transform: translateY(-10px); /* Label'ı aşağıya kaydır */
  }
}